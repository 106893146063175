import React from 'react';

import sadImage from '../../assets/images/sad.png';
import { useApp } from '../../providers/AppProvider';

function ErrorModal({ error }) {
    const { setShowErrorModal } = useApp();

    return (
        <section className="popup-erro">
            <div className="content-popup grid_4">
                <h4>
                    <i>
                        <img src={sadImage} width="64" height="64" alt="Sad" />
                    </i>
                    <span>Não foi possível efetuar a compra.</span>
                </h4>
                <p>{error}</p>
                <a
                    href="/"
                    onClick={e => {
                        e.preventDefault();
                        setShowErrorModal(false);
                    }}
                    className="card"
                >
                    Tentar de novo
                </a>
            </div>
        </section>
    );
}

export default ErrorModal;
