import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import ReactInputMask from 'react-input-mask';

function MaskedInput({ type, name, className = null, ...rest }) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value) {
                ref.setInputValue(value);
            },
            clearValue(ref) {
                ref.setInputValue('');
            },
        });
    }, [fieldName, registerField]);

    return (
        <>
            <p className={className}>
                <ReactInputMask
                    type={type}
                    ref={inputRef}
                    defaultValue={defaultValue}
                    {...rest}
                />
                {error && className && <span className="error">{error}</span>}
            </p>
            {error && !className && <p className="error">{error}</p>}
        </>
    );
}

export default MaskedInput;
