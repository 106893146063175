import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../../pages/Home';
import Success from '../../pages/Success';
import NotFound from '../../pages/NotFound';
import RedirectRoute from '../../components/RedirectRoute';
import AppProvider from '../../providers/AppProvider';

function MainRoutes() {
    return (
        <Router>
            <AppProvider>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <RedirectRoute path="/sucesso" exact component={Success} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </AppProvider>
        </Router>
    );
}

export default MainRoutes;
