import React from 'react';

function OfferCard({ offer }) {
    return (
        <div className="plan">
            <h2>
                <strong>{String(offer.speed).replace('.', ',')}GB</strong>
                <span>{offer.speed_description}</span>
            </h2>
            <div className="apps">
                <h3>Apps Inclusos</h3>
                {offer.apps.map(app => {
                    return (
                        <img
                            key={app.name}
                            src={app.uri}
                            width="50"
                            height="50"
                            alt={app.name}
                        />
                    );
                })}
            </div>
            <div className="ligacoes">
                <h3>{offer.apps_description}</h3>
            </div>
            <div className="redes-sociais">
                <h5>{offer.social_description}</h5>
                {offer.socials.map(media => {
                    return (
                        <img
                            key={media.name}
                            src={media.uri}
                            width="38"
                            height="39"
                            alt={media.name}
                        />
                    );
                })}
            </div>
            <div className="price">
                R$
                <strong>
                    {String(offer.price.toFixed(2)).replace('.', ',')}
                    {offer.price_obs && <i>*</i>}
                </strong>
                /MÊS
                <br />
                {offer.price_obs && <small>{offer.price_obs}</small>}
                <span>
                    {offer.payment_mode}
                    <em>{offer.permanency_description}</em>
                </span>
            </div>
        </div>
    );
}

export default OfferCard;
