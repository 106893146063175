import React, { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

const AppContext = createContext();

function AppProvider({ children }) {
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
    const [order, setOrder] = useState(null);

    return (
        <AppContext.Provider
            value={{
                showErrorModal,
                showLoadingOverlay,
                order,
                setShowErrorModal,
                setShowLoadingOverlay,
                setOrder,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export const useApp = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error(
            'You must sorround your components with AppProvider before call useApp',
        );
    }

    return context;
};

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default AppProvider;
