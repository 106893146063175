import React from 'react';

import sadImage from '../../assets/images/sad.png';

function OfferErrorModal() {
    return (
        <section className="popup-erro">
            <div className="content-popup grid_4">
                <h4>
                    <i>
                        <img src={sadImage} width="64" height="64" alt="Sad" />
                    </i>
                    <span>Não é possível continuar</span>
                </h4>
                <br />
                <p>
                    Não há oferta válida informada no parâmetro da URL. Por
                    favor verifique se copiou corretamente o link enviado no
                    SMS.
                </p>
            </div>
        </section>
    );
}

export default OfferErrorModal;
