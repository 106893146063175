import React from 'react';

function LoadingOverlay() {
    return (
        <section className="overlay-container">
            <div className="loadingio-spinner-spin-yfxl1chk6xr">
                <div className="ldio-kotnoqzfdo">
                    <div>
                        <div />
                    </div>
                    <div>
                        <div />
                    </div>
                    <div>
                        <div />
                    </div>
                    <div>
                        <div />
                    </div>
                    <div>
                        <div />
                    </div>
                    <div>
                        <div />
                    </div>
                    <div>
                        <div />
                    </div>
                    <div>
                        <div />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LoadingOverlay;
