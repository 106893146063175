import React from 'react';

function Footer() {
    return (
        <footer className="m2b-footer">
            <div className="container">
                <h6>
                    Desenvolvido por
                    <a
                        href="https://mobi2buy.com/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        Mobi2buy
                    </a>
                </h6>
            </div>
        </footer>
    );
}

export default Footer;
