import React from 'react';

import '../../assets/css/maintenance.css';

import logImage from '../../assets/images/logo_tim.png';

function NotFound() {
    return (
        <div className="not-found">
            <section>
                <img src={logImage} width="352" height="169" alt="Logo" />
                <h1>Página não encontrada</h1>
                <hr />
                <h2>Verifique se digitou corretamente o endereço da página.</h2>
            </section>
        </div>
    );
}

export default NotFound;
