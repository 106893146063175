import React from 'react';

import '../../assets/css/responsive.css';
import '../../assets/css/style.css';

import logoImg from '../../assets/images/logo.png';
import C6BankImage from '../../assets/images/logo-c6bank.png';
import androidImage from '../../assets/images/android.png';
import appleImage from '../../assets/images/apple.png';

function Success() {
    return (
        <div className="success">
            <header>
                <div className="container">
                    <div className="logo">
                        <img src={logoImg} width="105" height="30" alt="Logo" />
                    </div>
                </div>
            </header>
            <section className="mensagem">
                <div className="container">
                    <div className="row">
                        <div className="grid_6">
                            <h1>
                                Parabéns por escolher o{' '}
                                <strong>TIM Controle!</strong> Recebemos seu
                                pedido e ele será processado em até 72h.
                            </h1>
                            <h2>
                                Não esqueça! Abra sua conta no{' '}
                                <img
                                    src={C6BankImage}
                                    width="140"
                                    height="28"
                                    alt="CBank"
                                />{' '}
                                e ganhe mais <strong>4GB de dados</strong> na
                                sua oferta TIM Controle.
                                <a
                                    href="https://www.c6bank.com.br/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    https://www.c6bank.com.br
                                </a>
                                <br />
                                <span> Baixar o App:</span>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.c6bank.app"
                                    target="_blank"
                                    className="app_bank"
                                    rel="noreferrer"
                                >
                                    <img src={androidImage} alt="Android" />
                                </a>
                                <a
                                    href="https://apps.apple.com/br/app/c6-bank/id1463463143"
                                    target="_blank"
                                    className="app_bank"
                                    rel="noreferrer"
                                >
                                    <img src={appleImage} alt="Apple" />
                                </a>
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Success;
