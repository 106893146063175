import * as yup from 'yup';
import { parse, isValid, isAfter, isBefore, subYears } from 'date-fns';

yup.addMethod(yup.string, 'isValidCpf', function (message) {
    return this.test({
        message,
        name: 'isValidCpf',
        exclusive: true,
        test(value) {
            const cpf = value.replace(/\D/g, '').padStart(11, '0');

            while (cpf.length < 11) cpf = '0' + cpf;
            var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
            var a = [];
            var b = new Number();
            var c = 11;
            for (var i = 0; i < 11; i++) {
                a[i] = cpf.charAt(i);
                if (i < 9) b += a[i] * --c;
            }
            var x = 0;
            if ((x = b % 11) < 2) {
                a[9] = 0;
            } else {
                a[9] = 11 - x;
            }
            b = 0;
            c = 11;
            for (var y = 0; y < 10; y++) b += a[y] * c--;
            if ((x = b % 11) < 2) {
                a[10] = 0;
            } else {
                a[10] = 11 - x;
            }

            if (
                cpf.charAt(9) != a[9] ||
                cpf.charAt(10) != a[10] ||
                cpf.match(expReg)
            ) {
                return false;
            }

            return true;
        },
    });
});

yup.addMethod(yup.string, 'isValidaDate', function (message) {
    return this.test({
        message,
        name: 'isValidaDate',
        exclusive: true,
        test(value) {
            const data = parse(value, 'dd/MM/yyyy', new Date());
            const dataMinima = subYears(new Date(), 18);
            const dataMaxima = subYears(new Date(), 100);

            if (!isValid(data)) {
                return false;
            }

            if (isAfter(data, dataMinima) || isBefore(data, dataMaxima)) {
                return false;
            }

            return true;
        },
    });
});

yup.addMethod(yup.string, 'isTinyName', function (message) {
    return this.test({
        message,
        name: 'isTinyName',
        exclusive: true,
        test(value) {
            const parsedValue = value.trim().split(' ');
            let isValidName = true;

            if (parsedValue.length <= 2) {
                parsedValue.forEach(currentValue => {
                    if (currentValue.length < 3) {
                        isValidName = false;
                    }
                });
            }

            return parsedValue.length >= 2 && isValidName;
        },
    });
});

yup.addMethod(yup.string, 'isCellPhone', function (message) {
    return this.test({
        message,
        name: 'isCellPhone',
        exclusive: true,
        test(value) {
            const parsedValue = value.trim().replace(/\D/g, '');

            // Verifying if it is a cellphone number
            if (parsedValue.length === 11) {
                const ddd = parsedValue.substring(0, 2) * 1;

                // Does not exists DDD smaller than 11 in Brazil
                if (ddd < 11) {
                    return false;
                }

                const thisNumber = parsedValue.substring(2);
                const firstDigit = thisNumber.substring(1, 0) * 1;

                if (firstDigit !== 9) {
                    // It is not a valid cellphone in Brazil
                    return false;
                }

                return true;
            }

            return false;
        },
    });
});

export default {
    name: yup
        .string()
        .isTinyName('Nome inválido')
        .required('Insira seu nome completo'),
    cpf: yup
        .string()
        .isValidCpf('Insira um CPF válido')
        .required('Insira seu CPF'),
    rg: yup.string().required('Insira seu RG'),
    mother: yup
        .string()
        .isTinyName('Nome inválido')
        .required('Insira o nome completo da sua mãe'),
    birthday: yup
        .string()
        .isValidaDate('Insira uma data válida no formato dd/mm/aaaa')
        .required('Insira sua data de nascimento'),
    msisdn: yup
        .string()
        .isCellPhone('Insira seu número Tim Pré com DDD')
        .required('Insira seu número Tim Pré com DDD'),
    email: yup
        .string()
        .email('Insira um e-mail válido')
        .required('Insira seu e-mail'),
    cep: yup.string().required('Insira seu CEP'),
    number: yup.string().required('Insira o número do seu endereço'),
    due_date: yup.string().required('Escolha uma data de vencimento'),
};
