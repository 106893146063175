import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import * as yup from 'yup';

import Footer from '../../components/Footer';

import logoImg from '../../assets/images/logo.png';
import Input from '../../components/Input';
import MaskedInput from '../../components/MaskedInput';
import checkoutSchema from '../../utils/checkoutSchema';
import RadioPaymentDay from '../../components/RadioPaymentDay';

import viaCep from '../../services/viacep';
import ErrorModal from '../../components/ErrorModal';
import OfferErrorModal from '../../components/OfferErrorModal';
import LoadingOverlay from '../../components/LoadingOverlay';
import OfferCard from '../../components/OfferCard';

import { useApp, useQuery } from '../../providers/AppProvider';
import smartware from '../../services/smartware';

function Home() {
    const query = useQuery();
    const history = useHistory();
    const {
        showErrorModal,
        showLoadingOverlay,
        setShowErrorModal,
        setShowLoadingOverlay,
        setOrder,
    } = useApp();
    const [offer, setOffer] = useState(false);
    const [offerError, setOfferError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [addressCep, setAddressCep] = useState(null);
    const [address, setAddress] = useState({
        logradouro: '',
        bairro: '',
        localidade: '',
        uf: '',
    });
    const [gtmData, setGtmData] = useState(null);
    const formRef = useRef(null);
    const paymentDays = [
        { value: '01', label: '01' },
        { value: '07', label: '07' },
        { value: '10', label: '10' },
        { value: '12', label: '12' },
        { value: '15', label: '15' },
        { value: '20', label: '20' },
    ];

    const searchAddressByCep = async () => {
        const cep = addressCep.replace(/\D/g, '');

        viaCep
            .get(`/ws/${cep}/json/`)
            .then(response => {
                const { logradouro, bairro, localidade, uf } = response.data;
                setAddress({ logradouro, bairro, localidade, uf });
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleSubmit = async data => {
        try {
            setShowLoadingOverlay(true);

            // Remove all previous errors
            formRef.current.setErrors({});

            const schema = yup.object().shape(checkoutSchema);

            await schema.validate(data, {
                abortEarly: false,
            });

            if (gtmData) {
                Object.assign(data, gtmData);
            }

            smartware
                .post('api/v1/tim-smart-message/orders', data)
                .then(() => {
                    setOrder(data);
                    setShowLoadingOverlay(false);
                    history.push('/sucesso');
                })
                .catch(err => {
                    setShowErrorModal(true);

                    if (err.response) {
                        setErrorMessage(err.response.data.message);
                    } else {
                        console.log(err);
                    }

                    setShowLoadingOverlay(false);
                });
        } catch (err) {
            const validationErrors = {};

            if (err instanceof yup.ValidationError) {
                err.inner.forEach(error => {
                    validationErrors[error.path] = error.message;
                });

                formRef.current.setErrors(validationErrors);
            } else {
                setShowErrorModal(true);
                setErrorMessage(
                    'Ocorreu um erro ao processar sua solicitação. Por favor tente outra vez.',
                );
            }

            setShowLoadingOverlay(false);
        }
    };

    useEffect(() => {
        const offer_code = query.get('product_offer');
        const utm_campaign = query.get('utm_campaign');
        const utm_source = query.get('utm_source');
        const utm_medium = query.get('utm_medium');
        const gtmDataObject = { utm_campaign, utm_source, utm_medium };

        if (utm_campaign && utm_source && utm_medium) {
            setGtmData(gtmDataObject);
        }

        if (!offer_code) {
            setOfferError(true);
        } else {
            setShowLoadingOverlay(true);

            smartware
                .get(`api/v1/tim-smart-message/offers?offer_code=${offer_code}`)
                .then(response => {
                    setOffer(response.data.offer);
                    setShowLoadingOverlay(false);
                })
                .catch(err => {
                    if (err.response) {
                        console.log(err.response.data);
                    } else {
                        console.log(err);
                    }
                    setOfferError(true);
                    setShowLoadingOverlay(false);
                });
        }
    }, []);

    return (
        <div className="login">
            <section className="popup boleto">
                <div className="head">
                    <img src={logoImg} width="105" height="30" alt="Logo" />
                </div>
                <div className="form-group grid_10">
                    <Form
                        ref={formRef}
                        onSubmit={data => {
                            handleSubmit(data);
                        }}
                    >
                        {offer && (
                            <Input
                                type="hidden"
                                name="product_offered"
                                defaultValue={offer.code}
                            />
                        )}
                        <div className="row gutters">
                            <div className="col grid_3">
                                <h4>Plano escolhido:</h4>
                                {offer && <OfferCard offer={offer} />}
                            </div>
                            <div className="col grid_6">
                                <h4>Seus dados</h4>
                                <Input
                                    type="text"
                                    name="name"
                                    placeholder="Seu nome"
                                />
                                <div className="row gutters">
                                    <div className="col grid_6">
                                        <MaskedInput
                                            type="tel"
                                            name="cpf"
                                            mask="999.999.999-99"
                                            placeholder="CPF"
                                        />
                                    </div>
                                    <div className="col grid_6">
                                        <Input
                                            type="number"
                                            name="rg"
                                            placeholder="RG"
                                            maxLength={30}
                                        />
                                    </div>
                                </div>
                                <Input
                                    type="text"
                                    name="mother"
                                    placeholder="Nome da Mãe"
                                />
                                <div className="row gutters">
                                    <div className="col grid_6">
                                        <MaskedInput
                                            type="tel"
                                            name="birthday"
                                            mask="99/99/9999"
                                            placeholder="Data de Nascimento"
                                        />
                                    </div>
                                    <div className="col grid_6">
                                        <MaskedInput
                                            type="tel"
                                            name="msisdn"
                                            mask="(99)99999-9999"
                                            placeholder="Seu número Tim Pré com DDD"
                                        />
                                    </div>
                                </div>
                                <Input
                                    type="text"
                                    name="email"
                                    placeholder="E-mail"
                                />
                                <br />
                                <h4>Seu endereço</h4>
                                <div className="row gutters">
                                    <MaskedInput
                                        type="tel"
                                        name="cep"
                                        mask="99999-999"
                                        className="col grid_4"
                                        placeholder="CEP"
                                        onChange={e => {
                                            setAddressCep(
                                                e.currentTarget.value,
                                            );
                                        }}
                                        onBlur={e => {
                                            searchAddressByCep(e);
                                        }}
                                    />
                                    <Input
                                        type="text"
                                        name="address"
                                        value={address.logradouro}
                                        readOnly
                                        className="col grid_8"
                                        placeholder="Rua, Avenida, Travesa..."
                                    />
                                </div>
                                <div className="row gutters">
                                    <Input
                                        type="number"
                                        name="number"
                                        className="col grid_4"
                                        placeholder="Número"
                                    />
                                    <Input
                                        type="text"
                                        name="complement"
                                        className="col grid_8"
                                        placeholder="Complemento"
                                    />
                                </div>
                                <div className="row gutters">
                                    <Input
                                        type="text"
                                        name="neighborhood"
                                        value={address.bairro}
                                        readOnly
                                        className="col grid_4"
                                        placeholder="Bairro"
                                    />
                                    <Input
                                        type="text"
                                        name="city"
                                        value={address.localidade}
                                        readOnly
                                        className="col grid_4"
                                        placeholder="Cidade"
                                    />
                                    <Input
                                        type="text"
                                        name="state"
                                        value={address.uf}
                                        readOnly
                                        className="col grid_4"
                                        placeholder="Estado"
                                        maxLength="2"
                                    />
                                </div>
                            </div>
                            <div className="col grid_3">
                                <h4>Escolha o vencimento:</h4>
                                <div className="vencimento row gutters">
                                    <RadioPaymentDay
                                        onChange={() => {}}
                                        options={paymentDays}
                                        name="due_date"
                                        id="bill"
                                    />
                                </div>
                                <p>
                                    <input
                                        type="submit"
                                        name="enviarBoleto"
                                        value="CONTRATAR AGORA"
                                    />
                                </p>
                            </div>
                        </div>
                    </Form>
                </div>
            </section>

            {offerError && <OfferErrorModal />}
            {showErrorModal && <ErrorModal error={errorMessage} />}
            {showLoadingOverlay && <LoadingOverlay />}
            <Footer />
        </div>
    );
}

export default Home;
