import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

function RadioPaymentDay({ name, id, options, ...rest }) {
    const inputRef = useRef([]);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: refs => {
                return refs.filter(ref => ref.checked).map(ref => ref.value)[0];
            },
        });
    }, [fieldName, registerField, defaultValue]);

    return (
        <>
            {options.map((item, index) => (
                <div key={item.value} className="col grid_2">
                    <input
                        type="radio"
                        defaultValue={item.value}
                        name={name}
                        id={`radio-${id}-${item.label}`}
                        ref={ref => {
                            inputRef.current[index] = ref;
                        }}
                        {...rest}
                    />
                    <label htmlFor={`radio-${id}-${item.label}`}>
                        <p>{item.label}</p>
                    </label>
                </div>
            ))}
            {error && <span className="error inline">{error}</span>}
        </>
    );
}

export default RadioPaymentDay;
