import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

function Input({ type, name, className = null, ...rest }) {
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <>
            <p className={className}>
                <input
                    type={type}
                    ref={inputRef}
                    defaultValue={defaultValue}
                    {...rest}
                />
                {error && className && <span className="error">{error}</span>}
            </p>
            {error && !className && <p className="error">{error}</p>}
        </>
    );
}

export default Input;
