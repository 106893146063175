import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useApp } from '../../providers/AppProvider';

function RedirectRoute({ component: Component, ...rest }) {
    const { order } = useApp();
    const location = useLocation();

    return (
        <Route
            {...rest}
            render={props => {
                if (order) {
                    return <Component {...props} />;
                }

                return (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: {
                                from: location,
                            },
                        }}
                    />
                );
            }}
        />
    );
}

export default RedirectRoute;
